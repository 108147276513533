import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADMINISTRACAO_PORT}${process.env.VUE_APP_API_PATH}`;

export default {
    conectar(googleDto) {
        return axiosJwt.post(`${api}/controleacesso/google/conectar`, googleDto);
    },

    obterUsuario() {
        return axiosJwt.get(`${api}/controleacesso/google/usuario`);
    },

    desconectar() {
        return axiosJwt.patch(`${api}/controleacesso/google/desconectar`);
    },
};
