<template>
    <Card>
        <template #title>Google - Usuário Conectado </template>
        <template #content>
            <erros-box :erros="erros"></erros-box>
            <div v-if="usuario">
                <detalhe titulo="Nome" :size="titleSize">{{ usuario?.name }}</detalhe>
                <detalhe titulo="Email" :size="titleSize">{{ usuario?.email }}</detalhe>
            </div>
            <Button v-if="!usuario" label="Conectar" icon="pi pi-google" @click="conectar" />
        </template>
        <template #footer v-if="usuario">
            <Button v-if="usuario" label="Desconectar" icon="pi pi-google" @click="desconectar" />
        </template>
    </Card>
</template>

<script>
import GoogleService from './services';

export default {
    data() {
        return {
            usuario: null,
            titleSize: '50',
            erros: [],
        };
    },

    methods: {
        obterUsuario() {
            this.$store.dispatch('addRequest');
            GoogleService.obterUsuario().then((response) => {
                if (response && response.success) {
                    this.usuario = response.data;
                } else {
                    this.usuario = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        async conectar() {
            try {
                const authCode = await this.$gAuth.getAuthCode();
                let googleDto = {
                    authCode: authCode,
                };
                GoogleService.conectar(googleDto).then((response) => {
                    if (response && response.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Google',
                            detail: 'Conta conectada com sucesso',
                            life: 3000,
                        });
                        this.obterUsuario();
                    } else {
                        this.erros = response.errors;
                    }
                    this.$store.dispatch('removeRequest');
                });
            } catch (error) {
                console.error(error);
                return null;
            }
        },

        async desconectar() {
            try {
                GoogleService.desconectar().then((response) => {
                    if (response && response.success) {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Google',
                            detail: 'Conta desconectada com sucesso',
                            life: 3000,
                        });
                        this.obterUsuario();
                    } else {
                        this.erros = response.errors;
                    }
                    this.$store.dispatch('removeRequest');
                });
            } catch (error) {
                console.error(error);
                return null;
            }
        },
    },

    mounted() {
        this.obterUsuario();
    },
};
</script>